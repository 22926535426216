import BaseComponent from './BaseComponent'

class Input extends BaseComponent {
  constructor(componentName) {
    super(componentName)
  }

  init(component) {
    const element = component.element

    this.addCustomEvents(element)
    this.checkForFocus(element)
    this.checkForValues(element)
  }

  addCustomEvents(element) {
    element.addEventListener('keyup', () => {
      this.checkForValues(element)
    })
  }

  checkForFocus(element) {
    if (element.classList.contains('is-focused')) element.focus()
  }

  checkForValues(element) {
    element.classList[element.value && element.value.length !== 0 ? 'add' : 'remove']('has-content')
  }
}

export default Input
