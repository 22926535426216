import BaseComponent from './BaseComponent'

class AjaxTrigger extends BaseComponent {
  constructor(componentName){
    super(componentName)
  }

  init(component) {
    const element = component.element
    this.addCustomEvents(element)
  }

  addCustomEvents(element) {
    element.addEventListener('click', (e) => {
      e.preventDefault()
      e.stopPropagation()

      const url = element.href
      const method = element.dataset.method
      app.ajax.rails({ url, method })
    })
  }
}

export default AjaxTrigger
