import BaseComponent from './BaseComponent'

class InputRadio extends BaseComponent {
  constructor(componentName){
    super(componentName)
  }


  init(component) {
    this.checkIfToggle(component)
  }

  checkIfToggle(component) {
    const element = component.element
    const toggle = element.dataset.toggle

    if(toggle) {
      const radios = element.querySelectorAll('input[type="radio"]')
      for(let radio of radios) {
        radio.addEventListener('change', () => {
          const fieldsets = radio.form.querySelectorAll(`fieldset[data-toggle="${toggle}"]`);
          const divs      = radio.form.querySelectorAll(`div[data-toggle="${toggle}"]`);

          for(let fieldset of fieldsets) {
            fieldset.setAttribute('aria-expanded', fieldset.dataset.toggleValue == radio.value ? radio.checked : false)
          }

          for(let div of divs) {
            if(!div.classList.contains('input-radio-wrapper')) {
              div.setAttribute('aria-expanded', div.dataset.toggleValue == radio.value ? radio.checked : false);
            }
          }
        })
      }
    }
  }

}
export default InputRadio
