import BaseComponent from './BaseComponent'

class DropdownTrigger extends BaseComponent {
  constructor(componentName){
    super(componentName)
  }

  init(component) {
    this.addCustomEvents(component)
  }

  addCustomEvents(component) {
    this.toggleDropdownState(component.element)
  }

  toggleDropdownState(element) {
    element.addEventListener('click', () => {
      const expanded = element.getAttribute('aria-expanded') == 'true'
      if(!expanded) this.collapseAllDropdowns()
      element.setAttribute('aria-expanded', !expanded)
    })
  }

  collapseAllDropdowns() {
    for(let component of this.pool) {
      component.element.setAttribute('aria-expanded', false)
    }
  }
}

export default DropdownTrigger
