import Input from './Input'

class InputPassword extends Input {
  constructor(componentName, autoInit){
    super(componentName)
  }


  init(component) {
    this.addCustomEvents(component)
  }


  addCustomEvents(component) {
    const inputPassword      = component.element.getElementsByClassName('password')[0],
          inputPasswordText  = component.element.getElementsByClassName('password--text')[0],
          iconTogglePassword = component.element.getElementsByClassName('ic--password')[0]

    // MIRROR TO INPUT TEXT
    inputPassword.addEventListener('keyup', () => {
      // Post value in the sibling
      inputPasswordText.value = inputPassword.value
    })

    // MIRROR TO INPUT PASSWORD
    inputPasswordText.addEventListener('keyup', () => {
      // Post value in the sibling
      inputPassword.value = inputPasswordText.value
    })

    // TOGGLE INPUTS
    iconTogglePassword.addEventListener('click', () => {
      // Toggle between inputs & icon
      iconTogglePassword.classList.toggle('visible')
      inputPassword.classList.toggle('visible')
      inputPasswordText.classList.toggle('visible')
    })
  }
}

export default InputPassword
