import BaseComponent from './BaseComponent'

class Instance extends BaseComponent {
  constructor(componentName, autoInit) {
    super(componentName)
  }

  init(component) {
    const element = component.element
    this.addCustomEvents(element)
  }

  addCustomEvents(element) {}

  repaintList({htmlTag, type, title = {}}) {
    let parent = document.querySelector(`ul[data-model="${type}"`)
    removeAllChild(parent)
    parent.innerHTML = htmlTag

    const titleTag = document.querySelector(`li[data-title-type='${title.type}']`)
    if(titleTag) titleTag.textContent = title.text
  }

  create({htmlTag, type = 'content', position = undefined, id = null}) {
    let parent
    let newInstance = app.parser.parseToHTML(htmlTag)
    if(type === 'content') {
      parent = document.getElementsByClassName('list--contents')[0]
    } else {
      parent = document.querySelector(`ul[data-model="${type}"`)[0]
    }

    // Remove current instance to insert the new one ( replace case instead of update inner content )
    const currentInstance = document.querySelector(`article[class*="${type}"][data-id="${id}"]`)
    if(currentInstance) {
      const li = currentInstance.parentElement
      li.parentElement.removeChild(li)
    }

    // Append new Instance
    if(parent) {
      // Remove new dropped mirror content
      for(let item of parent.children) {
        if(item.classList.contains('draggable-source--placed')) parent.removeChild(item)
      }
      // Append new instance in his position
      const pos = parseInt(position)

      pos ? parent.insertBefore(newInstance, parent.children[pos]) : parent.appendChild(newInstance)
      this.checkRemainingInstance()
    } else {
      console.warn(`🦑 The instance doesn't know where to be placed`)
    }
  }

  delete({id, type = 'content', title = {} }) {
    const instances = document.querySelectorAll(`article[class*="${type}"][data-id="${id}"]`)
    for (let instance of instances) {
      const li = instance.parentElement
      const ul = li.parentElement
      ul.removeChild(li)

      // CONTENT IMAGE EXCEPTION
      const wrapper = ul.parentElement
      if(wrapper.classList.contains('content--gallery')) {
        if(ul.children.length === 1) {
          wrapper.classList.add('single')
        } else {
          wrapper.classList.remove('single')
        }
      }

      // Checking
      this.checkRemainingInstance()
    }

    const titleTag = document.querySelector(`li[data-title-type='${title.type}']`)
    if(titleTag) titleTag.textContent = title.text
  }

  checkRemainingInstance() {
    const contentsList = document.getElementsByClassName('list--contents')[0]

    if(contentsList) {
      const contents = document.querySelector('li[class*="item--content--created"]')
      const message  = contentsList.getElementsByClassName('message-wrapper')[0]
      message.dataset.visible = !contents ? true : false
    }
  }

  // updateInstance({htmlTag, type = 'content'}) {}
}

export default Instance
