import Headroom     from 'headroom.js'
import { throttle } from 'lodash'

class Navbar {
  constructor() {
    this.navbar   = document.querySelector('[role="navigation"]')
    this.burger   = document.getElementById('menu-icon')
    this.moreList = []

    this.options  = {
      offset : 40,   // vertical offset in px before element is first unpinned
      tolerance: {   // scroll tolerance in px per direction before state changes
        down: 0,
        up  : 0
      },
      classes : {
        initial   : 'navbar--initialized', // when element is initialised
        pinned    : 'navbar--pinned',      // when scrolling up
        unpinned  : 'navbar--unpinned',    // when scrolling down
        top       : 'navbar--top',         // when above offset
        notTop    : 'navbar--not-top',     // when below offset
        bottom    : 'navbar--bottom',      // when at bottom of scoll area
        notBottom : 'navbar--not-bottom'   // when not at bottom of scroll area
      }
    }

    this.init()
  }

  init() {
    if(this.navbar) {
      this.enableMobileMenu()
      this.resizeWhenScroll()
      setTimeout(() => {
        this.fitIn() // Initial check on load
      }, 500)
      window.addEventListener('resize', throttle(e => this.fitIn(), 500))
    } else {
      console.warn(`There is no navbar in this page`)
    }
  }

  resizeWhenScroll() {
    this.headroom = new Headroom(this.navbar, this.options)
    this.headroom.init()
  }

  enableMobileMenu() {
    if(this.burger) {
      this.burger.addEventListener('click', () => {
        this.toggleMenu()
      }, false)
    }
  }

  toggleMenu() {
    //Menu collapsed state
    this.burger.classList.toggle('is-active')

    //Menu collapsed state
    const collapsedBefore = this.navbar.dataset.collapsed === 'true'
    this.navbar.dataset.collapsed = !collapsedBefore
    const collapsed = this.navbar.dataset.collapsed === 'true'

    // Lock/Unlock scroll
    document.body.classList[collapsed ? 'remove' : 'add']('scroll-locked')
  }

  closeMobileMenu() {
    this.burger.classList.remove('is-active')
    this.navbar.dataset.collapsed = true
    document.body.classList.remove('scroll-locked')
  }

  fitIn() {
    const pageWidth = window.innerWidth > 0 ? window.innerWidth : screen.width
    const maxWidth = pageWidth < 992 ? 750 : pageWidth < 1200 ? 970 : 1170
    const projectsList = this.navbar.getElementsByClassName('list--project-links')[0]
    const actionsList  = this.navbar.getElementsByClassName('list--actions')[0]
    const navbarWidth = projectsList.offsetWidth + actionsList.offsetWidth
    const moreButtonWidth = 54 // Fixed value, needs to match the CSS
    const moreButtonString = `
      <li class='dropdown'>
        <button class='btn--more cmp-dropdown-trigger' role='switch' aria-checked='false' aria-label='More links'>
          <i class='material-icons'>more_horiz</i>
        </button>
        <ul class='list--dropdown'></ul>
      </li>
    `


    if(pageWidth < 768) { // enable mobile menu, reset all the ul
      const moreBtn = projectsList.getElementsByClassName('btn--more')[0]
      if(moreBtn) {
        const liDropdown = moreBtn.parentElement
        projectsList.removeChild(liDropdown)
        app.components.dropdownTrigger.destroyComponent(moreBtn.id)
      }
      while (this.moreList.length > 0) {
        const item = this.moreList.pop()
        projectsList.appendChild(item.li)
      }
    } else {
      if(navbarWidth > maxWidth) {
        let substractedWidth = 0
        for (let i = projectsList.children.length - 1; i >=0;  i--) {
          const li = projectsList.children[i]
          const liWidth = li.offsetWidth

          substractedWidth += liWidth
          // Store the last li to reuse it
          const liCloned = li.cloneNode(true)
          this.moreList.push({ li: liCloned, width: liWidth })
          // We take last li at the moment from the navbar ul
          projectsList.removeChild(li)
          // Navbar we will fit now just with these item/s substracted
          const moreBtn = projectsList.getElementsByClassName('btn--more')[0]
          if(moreBtn) {
            // Append more li to the dropdown content
            const dropdown = moreBtn.nextElementSibling
            dropdown.insertBefore(liCloned, dropdown.firstElementChild)
          } else {
            // Append the new moreButton and its dropdown content
            projectsList.appendChild(app.parser.parseToHTML(moreButtonString))
            const newMoreBtn = projectsList.getElementsByClassName('btn--more')[0]
            const dropdown = newMoreBtn.nextElementSibling
            dropdown.appendChild(liCloned)
          }
          if(projectsList.offsetWidth + moreButtonWidth + actionsList.offsetWidth <= maxWidth) break
        }
      } else {
        if (this.moreList.length > 0) {
          const moreBtn = projectsList.getElementsByClassName('btn--more')[0]
          const last = this.moreList[this.moreList.length - 1]

          if(this.moreList.length == 1) {
            if(projectsList.offsetWidth - moreButtonWidth + actionsList.offsetWidth + last.width <= maxWidth) {
              projectsList.insertBefore(last.li, projectsList.children[projectsList.childElementCount - 1])
              this.moreList.pop()

              const liDropdown = moreBtn.parentElement
              liDropdown.parentElement.removeChild(liDropdown)
              app.components.dropdownTrigger.destroyComponent(moreBtn.id)
            }
          } else if(projectsList.offsetWidth + moreButtonWidth + actionsList.offsetWidth + last.width <= maxWidth) {
            projectsList.insertBefore(last.li, projectsList.children[projectsList.childElementCount - 1])
            this.moreList.pop()
          }
        } else {
          const moreBtn = projectsList.getElementsByClassName('btn--more')[0]
          if(moreBtn) {
            const liDropdown = moreBtn.parentElement
            liDropdown.parentElement.removeChild(liDropdown)
            app.components.dropdownTrigger.destroyComponent(moreBtn.id)
          }
        }
      }
    } // end desktop calc
  }

}

export default Navbar
