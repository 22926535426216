import BaseComponent from './BaseComponent'
import autosize      from 'autosize'
import EasyMDE       from 'easymde'

class InputTextArea extends BaseComponent {
  constructor(componentName){
    super(componentName)
    this.defaultToolbar = ['heading-smaller', 'heading-bigger', 'link']
    this.options = {
      spellChecker: false,
      status: false,
      shortcuts: {
        'cleanBlock': null
      },
      // showIcons: ['heading', 'heading-smaller','heading-bigger', 'heading-1', 'heading-2', 'heading-3'],
      hideIcons: [
        'bold',
        'italic',
        'quote',
        'unordered-list',
        'ordered-list',
        'guide',
        'image',
        'preview',
        'side-by-side',
        'fullscreen',
      ],
      toolbar: this.defaultToolbar
    }

		this.fontName = 'f_grotesk'
		this.sizes = {
			'sang_bleu': {
				h1: { name: 'Text 100pt', visible: false },
				h2: { name: 'Text 80pt', visible: true },
				h3: { name: 'Text 40pt', visible: true },
				h4: { name: 'Text 40pt', visible: false },
				p: { name: 'Text 25pt', visible: true },
				h5: { name: 'Text 25pt', visible: false },
				h6: { name: 'Text 25pt', visible: false },
			},
			'f_grotesk': {
				h1: { name: 'Text 100pt', visible: false },
				h2: { name: 'Text 80pt', visible: true },
				h3: { name: 'Text 42pt', visible: true },
				h4: { name: 'Text 28pt', visible: true },
				p: { name: 'Text 25pt', visible: true },
				h5: { name: 'Text 25pt', visible: true },
				h6: { name: 'Text 20t', visible: true },
			}
		}
  }


  init(component) {
    const element  = component.element
    const markdown = element.dataset.markdown === 'true'

    if(markdown) {
      // Add alignment options if needed
      const customToolbar = {}
      if(element.dataset.alignments == 'true') customToolbar.alignments = true
      if(element.dataset.headings == 'true') customToolbar.headings = true
      this.addCustomToolbarButtons(element, customToolbar)

      // Init component library
      component.wysiwyg = new EasyMDE({ element, ...this.options })
      component.wysiwyg.value()
      component.wysiwyg.codemirror.options.readOnly = element.readOnly

      // Reset default toolbar
      this.options.toolbar = this.defaultToolbar
    } else {
      component.autosize = autosize(element)
    }

    this.addCustomEvents(component, markdown)
    this.checkForFocus(component, markdown)
  }


  addCustomToolbarButtons(element, toolbar) {
    const parent = element.parentElement
		const fontName = element.getAttribute('fontname')
		const fontSizes = this.sizes[fontName ?? this.fontName]

    this.options.toolbar = [
      // ...(toolbar.headings ? ['heading-smaller', 'heading-bigger'] : []),
      ...(toolbar.headings ? [
        {
          name: 'heading',
          className: 'fa fa-heading',
          title: 'Headings List',
          children: [
						...(fontSizes?.h2?.visible && [{
							name: 'heading-2',
              action: EasyMDE.toggleHeading2,
              className: 'fa fa-header header-2',
              title: fontSizes.h2.name
						}]),
            ...(fontSizes?.h3?.visible && [{
							name: 'heading-3',
              action: EasyMDE.toggleHeading3,
              className: 'fa fa-header header-3',
              title: fontSizes.h3.name
						}]),
						...(fontSizes?.h4?.visible && [{
							name: 'heading-4',
              action: (editor) => {
                editor.toggleHeading3()
                editor.toggleHeadingSmaller()
              },
              className: 'fa fa-header header-3',
              title: fontSizes.h4.name
						}]),
						...(fontSizes?.p?.visible && [{
							name: 'clean-block',
              action: EasyMDE.cleanBlock,
              className: 'fa fa-eraser',
              title: fontSizes.p.name
						}]),
						{
							name: 'heading-5',
							action: (editor) => {
								console.log('editor', editor)
								editor.toggleHeading3()
								editor.toggleHeadingSmaller()
								editor.toggleHeadingSmaller()
							},
							className: 'fa fa-header header-3',
							title: fontSizes.h5.name
						},
						{
							name: 'heading-6',
							action: (editor) => {
								console.log('editor', editor)
								editor.toggleHeading3()
								editor.toggleHeadingSmaller()
								editor.toggleHeadingSmaller()
								editor.toggleHeadingSmaller()
							},
							className: 'fa fa-header header-3',
							title: fontSizes.h6.name
						},
						// ...(fontSizes.h5.visible && {
						// 	name: 'heading-5',
            //   action: (editor) => {
            //     editor.toggleHeading3()
            //     editor.toggleHeadingSmaller()
            //     editor.toggleHeadingSmaller()
            //   },
            //   className: 'fa fa-header header-3',
            //   title: fontSizes.h5.name,
						// }),
            // ...(fontSizes.h6.visible && {
						// 	name: 'heading-6',
            //   action: (editor) => {
            //     editor.toggleHeading3()
            //     editor.toggleHeadingSmaller()
            //     editor.toggleHeadingSmaller()
						// 		editor.toggleHeadingSmaller()
            //   },
            //   className: 'fa fa-header header-3',
            //   title: fontSizes.h6.name,
						// })
          ]
        },
      ] : []),
      ...(toolbar.alignments ? [
        {
          name: 'alignleft',
          action: (editor) => {
            const inputWrapper = editor.element.parentElement
            inputWrapper.classList.remove('align-center')
            inputWrapper.classList.remove('align-right')
            inputWrapper.classList.add('align-left')

            editor.toolbarElements.aligncenter.classList.remove('visible')
            editor.toolbarElements.alignright.classList.remove('visible')
            editor.toolbarElements.alignleft.classList.add('visible')

            const alignInput = inputWrapper.querySelector('input[type="hidden"]')
            if(alignInput) alignInput.value = 'left'
          },
          className: `fa fa-align-left${parent.classList.contains('left') ? ' visible' : ''}`,
          title: 'Align left',
        },
        {
          name: 'aligncenter',
          action: (editor) => {
            const inputWrapper = editor.element.parentElement
            inputWrapper.classList.remove('align-left')
            inputWrapper.classList.remove('align-right')
            inputWrapper.classList.add('align-center')

            editor.toolbarElements.alignleft.classList.remove('visible')
            editor.toolbarElements.alignright.classList.remove('visible')
            editor.toolbarElements.aligncenter.classList.add('visible')

            const alignInput = inputWrapper.querySelector('input[type="hidden"]')
            if(alignInput) alignInput.value = 'center'
          },
          className: `fa fa-align-center${parent.classList.contains('center') ? ' visible' : ''}`,
          title: 'Align center',
        },
        {
          name: 'alignright',
          action: (editor) => {
            const inputWrapper = editor.element.parentElement
            inputWrapper.classList.remove('align-left')
            inputWrapper.classList.remove('align-center')
            inputWrapper.classList.add('align-right')

            editor.toolbarElements.alignleft.classList.remove('visible')
            editor.toolbarElements.aligncenter.classList.remove('visible')
            editor.toolbarElements.alignright.classList.add('visible')

            const alignInput = inputWrapper.querySelector('input[type="hidden"]')
            if(alignInput) alignInput.value = 'right'
          },
          className: `fa fa-align-right${parent.classList.contains('right') ? ' visible' : ''}`,
          title: 'Align right',
        }
      ] : []),
      {
        name: 'email',
        action: (editor) => {
          var cm = editor.codemirror;
          var stat = editor.getState(cm); // or true
          var options = editor.options;
          var url = 'mailto:';

          this._replaceSelection(cm, stat.link, options.insertTexts.link, url);
        },
        className: 'fa fa-envelope',
        title: 'Email'
      },
      'link'
    ]
  }


  addCustomEvents(component, markdown = true) {
    const element = component.element

    if(markdown) {
      const cm = component.wysiwyg.codemirror;

      cm.on('focus', () => { element.classList.add('is-focused') })
      cm.on('blur', () => { element.classList.remove('is-focused') })

      if(element.dataset.headings == 'true') {
        // const h2 = component.wysiwyg.toolbarElements['heading-2'];
        // const p  = component.wysiwyg.toolbarElements['clean-block'];
        const textareaWrapper = component.element.parentElement
        const currentTextType = textareaWrapper.querySelector('span[class="current-text-type"]')

				// New update for dynamic fonts
				const fontName = component.element.getAttribute('fontname')
				const fontSizes = this.sizes[fontName ?? this.fontName]
        const textType = {}
				Object.keys(fontSizes).map((key) => {
					textType[key] = fontSizes[key].name
				})

        // https://codemirror.net/doc/manual.html#api_marker
        cm.on('cursorActivity', (editor) => {
          const index = cm.getCursor().line
          const line = cm.display.renderedView[index]
          if(line) {
            const lineText = cm.display.renderedView[index].line.text
            if(lineText.startsWith('###### ')) currentTextType.textContent = textType.h5
            else if(lineText.startsWith('##### ')) currentTextType.textContent = textType.h5
            else if(lineText.startsWith('#### ')) currentTextType.textContent = textType.h4
            else if(lineText.startsWith('### ')) currentTextType.textContent = textType.h3
            else if(lineText.startsWith('## ')) currentTextType.textContent = textType.h2
            else if(lineText.startsWith('# ')) currentTextType.textContent = textType.h1
            else currentTextType.textContent = textType.p
          }
        })
      }
    } else {
      element.addEventListener('focus', () => {
        element.classList.add('is-focused')
      })

      element.addEventListener('blur', () => {
        element.classList.remove('is-focused')
      })
    }
  }

  checkForFocus(component, markdown) {
    if (component.element.classList.contains('is-focused')) {
      if(markdown) {
        component.wysiwyg.codemirror.focus()
      } else {
        component.element.focus()
      }
    }
  }

  _replaceSelection(cm, active, startEnd, url) {
    if(/editor-preview-active/.test(cm.getWrapperElement().lastChild.className))
      return;

    var text;
    var start = startEnd[0];
    var end = startEnd[1];
    var startPoint = cm.getCursor("start");
    var endPoint = cm.getCursor("end");
    if(url) {
      end = end.replace("#url#", url);
    }
    if(active) {
      text = cm.getLine(startPoint.line);
      start = text.slice(0, startPoint.ch);
      end = text.slice(startPoint.ch);
      cm.replaceRange(start + end, {
        line: startPoint.line,
        ch: 0
      });
    } else {
      text = cm.getSelection();
      cm.replaceSelection(start + text + end);

      startPoint.ch += start.length;
      if(startPoint !== endPoint) {
        endPoint.ch += start.length;
      }
    }
    cm.setSelection(startPoint, endPoint);
    cm.focus();
  }
}

export default InputTextArea
