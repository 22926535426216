import BaseComponent from './BaseComponent'

class InputCheckbox extends BaseComponent {
  constructor(componentName){
    super(componentName)
  }

  init(component) {
    this.checkIfToggle(component)
  }

  checkIfToggle(component) {
    const element = component.element
    const toggle = element.dataset.toggle
    if(toggle) {
      element.addEventListener('change', () => {
        const fieldsets = element.form.querySelectorAll(`fieldset[data-toggle="${toggle}"]`)
        const divs = element.form.querySelectorAll(`div[data-toggle="${toggle}"]`)

        for(let fieldset of fieldsets) {
          const fieldsetValue = fieldset.dataset.toggleValue == 'true';
          fieldset.setAttribute('aria-expanded', fieldsetValue == element.checked);
        }

        for(let div of divs) {
          const divValue = div.dataset.toggleValue == 'true';
          div.setAttribute('aria-expanded', divValue == element.checked);
        }
      })
    }
  }
}
export default InputCheckbox
