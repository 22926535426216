import BaseComponent from './BaseComponent'

class Clipboard extends BaseComponent {
  constructor(componentName){
    super(componentName)
  }

  init(component) {
    this.addCustomEvents(component.element)
  }

  addCustomEvents(element) {
    const triggerer = element.nextElementSibling
    // const triggerer = element.readOnly ? element.nextElementSibling : element
    triggerer.addEventListener('click', (e) => {
      this.copy(element)
    })
  }

  copy(element) {
    // Value will be stored as an input or as a data attribute if the trigger is another tag element
    // const valueToCopy = element.readOnly ? element.value : element.dataset.value
    const valueToCopy = element.value
    if(valueToCopy) {
      if(app.device.os === 'ios') {
        const selection = window.getSelection()
        const range     = document.createRange()

        range.selectNodeContents(code)
        selection.removeAllRanges()
        selection.addRange(range)
      } else if (element.select){
        element.select()
      }

      try {
        document.execCommand('copy')
        if(app.device.os === 'ios') selection.removeAllRanges()

        // Tooltip
        // const tooltip = document.getElementsByClassName('tooltip--code-copied')[0]
        // if(tooltip) app.components.tooltip.display(tooltip)

        element.blur()
      } catch (error) {
        console.log('Something wrong copying to clipboard, please do it manually')
      }
    }
  }
}

export default Clipboard
