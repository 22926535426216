import BaseComponent from './BaseComponent'
import noUiSlider    from 'nouislider'
import wNumb         from 'wnumb'   // Library for easy number formatting

class InputRange extends BaseComponent {
  constructor(componentName){
    super(componentName)

    this.options = {
      animate: true,
      animationDuration: 300,
      ariaFormat: wNumb({
        decimals: 0
      }),
      behaviour: 'tap-drag',
      connect: true,         // https://refreshless.com/nouislider/slider-options/#section-connect
      direction: 'ltr',
      format: wNumb({
        decimals: 0,
        // prefix: '€',
        suffix: ' €'
      }),
      keyboardSupport: true,
      limit: null, // Maximum distance between the handles
      margin: 50,  // Minimum distance between the handles
      orientation: 'horizontal',
      padding: 0,  // padding between handles and the side edges when reach min/max value
      range: {
        min: 0,
        max: 255
      },
      start: [0, 20],
      step: 10,
      tooltips: true,
    }
  }


  init(component) {
    const inputRange = component.element.previousElementSibling
    noUiSlider.create(inputRange, {...this.options, ...this.getCustomOptions(component) })

    this.updateValue(inputRange.noUiSlider, component.element)
  }

  getCustomOptions(component) {
    const opt = {}

    const rangeData = component.element.dataset.range
    const rangeSelectedData = component.element.dataset.rangeSelected

    // Min and max range of values
    if(rangeData) {
      const range = rangeData.split('..').map(Number)
      opt.range = { min: range[0], max: range[1] }
    }

    // Initial values selected
    if(rangeSelectedData) opt.start = rangeSelectedData.split('..').map(Number)

    return opt
  }

  updateValue(inputRange, input) {
    inputRange.on('end', (e) => {
      input.value = [].concat(...inputRange.get().map(value => value.match(/\d+/g).map(Number)))
    })
  }
}
export default InputRange
