import BaseComponent from './BaseComponent'
import { Sortable }  from '@shopify/draggable'

class DragAndDrop extends BaseComponent {
  constructor(componentName){
    super(componentName)
    this.className = componentName
    this.options = {
      draggable: '.sortable',
      distance: 65,
      delay: app.device.device === 'desktop' ? 200 : 500,
      mirror: {
        // appendTo: `.dropzone-wrapper`,
        constrainDimensions: true
      },
    }
  }

  init(component) {
    component.sortable = new Sortable(component.element, this.options)

    component.sortable.on('sortable:start', (e) => {
      // e.preventDefault()
      // e.stopPropagation()
      // alert('sortable:start')
      // alert('drag:start')
      const isPrevented = (element, classesToPrevent) => {
        let currentElem = element
        let isParent = false

        while (currentElem) {
          const hasClass = Array.from(currentElem.classList).some((cls) => classesToPrevent.includes(cls))
          if (hasClass) {
            isParent = true
            currentElem = undefined
          } else {
            currentElem = currentElem.parentElement
          }
        }

        return isParent
      }

      const currentTarget = e.data.dragEvent.sensorEvent.target
      if (isPrevented(currentTarget, ['cancel-drag'])) {
        e.cancel()
      }
    })
    component.sortable.on('sortable:stop', (e) => {
      this.updateTree(e.data.newContainer)
    })  // cuando se suelta definitivamente
  }

  updatePositions(dropzoneWrapper) {
    setTimeout(() => {
      const items = dropzoneWrapper.children
      let position = 1

      for (let item of items) {
        if(!item.classList.contains('draggable--original') || !item.classList.contains('draggable-mirror')) {
          // debugger
          item.dataset.position = position
          position++
        }
      }
    }, 400)
  }

  updateTree(destination) {
    let searchParent = true
    let element      = destination

    while (searchParent) {
      element = element.parentElement
      if (element.classList.contains('content-wrapper')) {
        this.updatePositions(destination)
        setTimeout(() => {
          this.updateDDBB(destination)
        }, 500)
        searchParent = false
      }
    }
  }

  updateDDBB(dropzoneWrapper) {
    const url = dropzoneWrapper.parentElement.parentElement.getAttribute('data-sort-url')
    const data = this.generateJSON(dropzoneWrapper)
    app.ajax.rails({ url: url, data: `sort_data=${data}`, method: 'POST' })
  }

  generateJSON(dropzoneWrapper) {
    let tempData = []
    const items = dropzoneWrapper.children
    for (let item of items) {
      const position = item.dataset.position
      const id       = item.firstElementChild.dataset.id
      const values   = { id, position }

      tempData.push(id)
    }
    // return JSON.stringify(tempData)
    return tempData
  }
}

export default DragAndDrop
