import BaseComponent from './BaseComponent'
import Pickr         from '@simonwep/pickr/dist/pickr.min'


class ColorPicker extends BaseComponent {
  constructor(componentName){
    super(componentName)

    this.options = {
      el: '.color-picker',
			default: null,
      components: {
        // Main components
        preview: true,
        opacity: false,
        hue: true,
        // Input / output Options
        interaction: {
          hex   : true,
          rgba  : true,
          hsla  : false,
          hsva  : false,
          cmyk  : true,
          input : true,
          clear : true,
          save  : true
        }
      }
    }
  }

  init(component) {
    const element       = component.element
    const colorText     = element.parentElement.nextElementSibling
    const customOptions = {
      el: element,
      swatches: ['#F44336', '#E91E63', '#9C27B0', '#673AB7']
    }

		const value = component.element.dataset.value

    component.colorPicker = Pickr.create({ ...this.options, ...customOptions })
    component.colorPicker.on('init', (instance) => {
      instance.setColor(!!value && value !== '' ? value : null)
    })

    this.addCustomEvents(component.colorPicker, colorText)
  }

  addCustomEvents(colorPicker, colorText) {
    colorPicker.on('save', newColor => {
      const value = newColor ? newColor.toHEX().toString() : ''
      colorText.value = value
    })


    colorText.addEventListener('click', () => {
      colorPicker.show()
    })
  }
}

export default ColorPicker
