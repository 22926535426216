import BaseComponent from './BaseComponent'

class Tooltip extends BaseComponent {
  constructor(componentName) {
    super(componentName)

    this.detect();
  }

  init(component) {
    this.addCustomEvents(component)
  }

  addCustomEvents(component) {
    const element = component.element
    // id = element.getAttribute('aria-describedby')
    // const tooltip = element.getElementsByClassName('tooltip')[0]
    const tooltip = element.lastElementChild

    if(tooltip.dataset.triggerOnHover) {
      element.addEventListener('mouseenter', () => {
        this.display(tooltip)
      })

      element.addEventListener('mouseleave', () => {
        this.close(tooltip)
      })
    } else {
      element.addEventListener('click', () => {
        this.display(tooltip)
      })

      element.addEventListener('blur', () => {
        setTimeout(() => { this.close(tooltip) }, 100)
      })

      // Close tooltip when user click ESC key
      element.addEventListener('keydown', (e) => {
        if (e.keyCode == 27) this.close(tooltip)
        return false
      })
    }


    if(app.device.device === 'mobile' || app.device.device === 'tablet') {
      element.addEventListener(app.device.os === 'ios' ? 'mouseout' : 'blur', () => {
        this.close(tooltip)
      })
    }
  }

  display(tooltip) {
    // console.log('tooltip.dataset.hidden', tooltip.dataset.hidden)
    if(tooltip) {
      if(tooltip.getAttribute('aria-hidden') === 'false') {
        this.close(tooltip)
      } else {
        tooltip.setAttribute('data-hidden', false)
        tooltip.setAttribute('aria-hidden', false)
      }
    }
  }

  close(tooltip, destroyAfter = false) {
    tooltip.setAttribute('aria-hidden', true)
    const duration =  tooltip.classList.contains('on-hover') ? 500 : 200

    setTimeout(() => {
      tooltip.setAttribute('data-hidden', true)
      if(destroyAfter && tooltip.parentElement) {
        tooltip.parentElement.removeChild(tooltip)
      }
    }, duration)
  }

  detect() {
    const buttons = document.querySelectorAll('button[title]')
    const links   = document.querySelectorAll('a[title]')

    this.dynamicTooltip(buttons)
    this.dynamicTooltip(links)
  }

  dynamicTooltip(items) {
    for(let item of items) {
      item.addEventListener('mouseenter', (e) => {
        const tooltipString = `
          <div class='tooltip on-hover' role='tooltip' aria-hidden='true' data-hidden='true'>
            <span class='tooltip__message'>${item.title}</span>
          </div>
        `
        item.dataset.title = item.title
        item.title = ''
        item.appendChild(app.parser.parseToHTML(tooltipString))
        const tooltip = item.getElementsByClassName('tooltip')[0]
        setTimeout(() => {
          this.display(tooltip)
        }, 200)
      })

      item.addEventListener('mouseleave', (e) => {
        if(item.dataset.title && item.dataset.title.length > 0) {
          item.title = item.dataset.title
          item.dataset.title = ''
        }

        const tooltips = item.getElementsByClassName('tooltip')
        for(let tooltip of tooltips) {
          if(tooltip) this.close(tooltip, true)
        }
      })
    }
  }


}
export default Tooltip
