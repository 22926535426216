import BaseComponent from './BaseComponent'
import { Sortable }  from '@shopify/draggable'
import { throttle }  from 'lodash'

class Dropzone extends BaseComponent {
  constructor(componentName){
    super(componentName)
    this.options = {
      draggable: '.sortable',
      distance: 65,
      delay: app.device.device === 'desktop' ? 200 : 500,
      mirror: { // https://github.com/Shopify/draggable/tree/master/src/Draggable/Plugins/Mirror
        // appendTo: `.${componentName}`,
        constrainDimensions: true
      }
    }

    this.showcase = document.querySelector('ul[data-showcase="true"]')
    this.dropzones = document.querySelectorAll(`.${componentName}`)
    this.sortable  = new Sortable(this.dropzones, this.options)

    this.sortable.on('drag:start', (e) => {
      const isTheShowcase = e.data.sourceContainer.showcase == 'true'
      if(!isTheShowcase) {
        app.components.dropzone.showcase.style.pointerEvents = 'none'
      }
    })

    // this.sortable.on('sortable:start', (e) => {
    //   console.log('sortable:start')
    // })

    this.sortable.on('drag:stop', (e) => {
      app.components.dropzone.showcase.style.pointerEvents = 'auto'
    })

    this.sortable.on('sortable:stop', (e) => {
      const showcase = e.oldContainer.dataset.showcase == 'true'
      const isNewContent = showcase ? (e.oldContainer != e.newContainer ? true : false) : false

      const data = e.dragEvent.data
      const originalSource = data.originalSource
      if(isNewContent) {
        // Keep the original Content in the showcase to be able to re-use it
        const showcaseContent = originalSource.cloneNode(true)
        originalSource.parentElement.insertBefore(showcaseContent, originalSource, originalSource.parentElement.id)

        // If content spacer -> autosubmit
        // if (originalSource.firstElementChild.dataset.modalTarget == 'modal--content--one_column--spacer') {
        if (false) {
          app.components.dropzone.autoSubmitContentDrop(data.source)
        } else {
          // Else -> Open the new content modal
          app.components.dropzone.openNewContentModal(data.source)
        }
      }

      this.updatePosition(originalSource.parentElement.id)
    })
  }

  init(component) {
    const element = component.element
    if(element.dataset.affix == 'true') {
			const availableContentsList = document.getElementsByClassName('list--available-contents')[0];

      setTimeout(() => {
				const contentsList = document.getElementsByClassName('list--contents')[0]
        const availableContentsHeight = availableContentsList.children.length * 75
        contentsList.style.minHeight = availableContentsHeight + 'px'

        window.addEventListener('scroll', throttle((e) => {
					const bottom = contentsList.getBoundingClientRect().bottom
          const top = contentsList.getBoundingClientRect().top

          element.dataset.fixed = top < 0 && bottom >= availableContentsHeight
        }, 75))
      }, 2000)
    }
  }

  openNewContentModal(item) {
    const modalTrigger = item.firstElementChild
    const modalTarget  = modalTrigger.dataset.modalTarget
    const modal        = document.querySelector(`li[data-modal="${modalTarget}"]`)
    if(modal) app.components.modalTrigger.openModal(modal)
    this.storePossibleNewContentPosition(modal)
  }

  autoSubmitContentDrop(item) {
    const modalTrigger = item.firstElementChild
    const modalTarget  = modalTrigger.dataset.modalTarget
    const modal        = document.querySelector(`li[data-modal="${modalTarget}"]`)

    if(modal) {
      this.storePossibleNewContentPosition(modal, true)
    }
  }

  storePossibleNewContentPosition(modal, submitAfter = false) {
    const form = modal.getElementsByClassName('form')[0]
    if(form) {
      const inputPosition = form.querySelector('input[name="content[position]"]')
      const position = this.getPosition()
      inputPosition.value = position

      if(submitAfter) app.components.form.submitDataOfForm(form)
    }
  }

  getPosition() {
    let position = 1
    const list   = document.getElementsByClassName('list--contents')[0]
    for (let item of list.children) {
      if (!item.classList.contains('message-wrapper')) {
        if(item.classList.contains('draggable')) break
        position++
      }
    }

    return position
  }

  updatePosition(listId) {
    setTimeout(() => {
      const list = document.getElementById(listId)
      const url  = list.getAttribute('data-sort-url')
      const contents = list.querySelectorAll('article[class*="content"]')

      let data = [] // position
      for(let content of contents) {
        const id = content.getAttribute('data-id').split('content--')[1]
        data.push(id)
      }

      if(url) app.ajax.rails({ url, data: `sort_data=${data}`, method: 'POST' })
    }, 350)
  }
}

export default Dropzone
