import BaseComponent from './BaseComponent'
import Swiper        from 'swiper'
import { throttle }  from 'lodash'


class SliderGallery extends BaseComponent {
  constructor(componentName, autoInit = true){
    super(componentName)

    this.options = {
      wrapperClass: 'slides-list',
      slideClass: 'slide',
      centeredSlides: false,
      // centerInsufficientSlides: true,
      freeMode: true,
      slidesPerView: 'auto',
      spaceBetween: 0,
      loop: false,
      navigation: {
        prevEl: '.slider__btn--prev',
        nextEl: '.slider__btn--next'
      },
      speed: 300,
      // watchOverflow: true,
    }
  }


  init(component) {
    component.isEnabled = false
    // scrollWidth to take in count the overflow slides
    // + 40 to give it more margin and avoid super close elements
    const element    = component.element
    const breakpoint = element.scrollWidth + 10
    component.fit    = element.offsetWidth >= breakpoint

    if (!component.fit || app.device.device == 'mobile') {
      component.slider = new Swiper(element, this.options)
      element.classList.add('slider-active')
      component.isEnabled = true
    }

    this.addCustomEvents(component)
  }


  update(element) {
    const component  = this.getComponentById(element.id)
    const slider     = component.slider
    const breakpoint = element.scrollWidth + 10

    component.fit = element.offsetWidth >= breakpoint

    if (!component.fit || app.device.device == 'mobile') {
      !!slider ? slider.update() : slider = new Swiper(element, this.options)
      element.classList.add('slider-active')
      component.isEnabled = true
    }
  }


  addCustomEvents(component) {
    const element = component.element;
    window.addEventListener('resize', throttle((e) => {
      if (component.fit) {
        if (!!component.slider) {
          // WARNING ERROR CHECK LIBRARY DESTROY METHOD UPDATE
          // component.slider.destroy(true, true);
          component.slider = undefined;
          element.classList.remove('slider-active');
          component.isEnabled = false;
        }
        return;
      } else {
        if (!component.isEnabled) {
          this.update(element);
        }
      }
    }, 500));

  }

}

export default SliderGallery;
