import BaseComponent from './BaseComponent'

class Vimeo extends BaseComponent {
  constructor(componentName, autoInit){
    // console.warn('componentName', componentName)
    super(componentName)
  }


  init(component) {
    const element = component.element

    this.getVimeoThumbnail(element)
  }

  getVimeoThumbnail(element) {
    const vimeoId = element.dataset.id
    const getJSON = async url => {
      try {
        const response = await fetch(url)
        // check if response worked (no 404 errors etc...)
        if(!response.ok) throw new Error(response.statusText)

        const data = await response.json() // get JSON from the response
        return data // returns a promise, which resolves to this data value
      } catch(error) {
        return error
      }
    }

    const vimeoJSON = getJSON(`https://vimeo.com/api/v2/video/${vimeoId}.json`).then(data => {
      const img = document.createElement('img')
      img.src = data[0].thumbnail_large
      img.alt = 'Vimeo thumbnail'
      element.appendChild(img)
    }).catch(error => {
      console.error(error)
    })

  }

}

export default Vimeo
