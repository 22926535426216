import Bowser from 'bowser'

class Device {
  constructor() {
    this.device
    this.browser
    this.os
    this.init()
  }

  init() {
    const html = document.getElementsByTagName('html')[0]
    const data = Bowser.getParser(window.navigator.userAgent)
    this.detectBrowser(data.parsedResult.browser)
    this.detectDevice(data.parsedResult.platform)
    this.detectOS(data.parsedResult.os)

    console.info(`*******\nBrowser: ${this.browser}\nDevice: ${this.device}\nOS: ${this.os}\n*******`)
    html.dataset.browser = this.browser
    html.dataset.device  = this.device
    html.dataset.os      = this.os
  }

  detectBrowser(browser) {
    this.browser = browser.name.toLowerCase()
  }

  detectDevice(platform) {
    this.device = platform.type.toLowerCase()
  }

  detectOS(os) {
    this.os = os.name.toLowerCase()
  }
}

export default Device
