import Input from './Input'

class InputNumber extends Input {
  constructor(componentName){
    super(componentName)
  }

  init(component) {
    const element = component.element

    this.addCustomEvents(element)
    this.checkForFocus(element)
    this.checkForValues(element)

    // this.enableCustomButtons()
  }

  // enableCustomButtons() {

  // }
}

export default InputNumber;
